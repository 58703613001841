<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="solution-header-container" :style="'background: url('+dynaBannerBg+')'">
      <div class="solution-header-text">
        <h1 data-caption-delay="0" class="wow fadeInLeft">DYNA4</h1>
        <p data-caption-delay="100" class="wow fadeInRight">提供高精度的车辆动力学模型以及虚拟仿真场景</p>
        <div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)"><div>立即咨询</div></div>
      </div>
    </div>
    <section class="vehicle-solution-sections solution-functions electric-solution-function wow fadeInLeft">
      <div class="container">
        <div class="normal-title">功能特点</div>
        <div
            class="all-function"
            @mouseenter="$refs.mySwiper.swiper.autoplay.stop()"
            @mouseleave="$refs.mySwiper.swiper.autoplay.start()"
        >
          <swiper :options="swiperOption" ref="mySwiper">
            <swiper-slide v-for="(item, index) in functionList" :key="index">
              <div class="card-item">
                <el-card class="solution-function-card" shadow="hover">
                  <div class="card-icon">
                    <img :src=" item.icon " alt="">
                  </div>
                  <div class="card-text">
                    <p>{{ item.content }}</p>
                  </div>
                </el-card>
              </div>
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>
    <section class="solution-application dyna-product-application wow fadeInRight">
      <div class="container">
        <div class="normal-title">应用领域</div>
        <div class="all-application">
          <div class="application-text">
            <ul>
              <li>驾驶员辅助系统及自动驾驶系统的域控制器测试验证：环境感知算法验证、舒适性功能验证、主动安全系统功能验证</li>
              <li>底盘控制系统的域控制器测试验证：制动控制、转向控制、半主动/主动悬架控制</li>
              <li>整车能量管理的测试验证：二氧化碳减排、能量管理预测功能</li>
              <li>内燃发动机控制器的测试验证</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="dyna-product-system-picture wow fadeInLeft">
      <div class="container">
        <div class="normal-title">系统图片</div>
        <div class="all-system-picture">
          <div class="system-picture">
            <img src="../../../assets/images/product-dyna-system-picture.png" alt="">
          </div>
        </div>
      </div>
    </section>
    <bzInfo style="margin-top: 0"/>
    <main-footer></main-footer>
  </div>
</template>

<script>
import mainNav from '@/components/MainNav';
import mainFooter from '@/components/MainFooter';
import dynaBannerBg from '@/assets/images/banner-product.jpg';
import dynaFunctionBg from '@/assets/images/solution-eletric-intro-pg.png';
import bzInfo from "@/components/BzInfo.vue";
import functionPic1 from '@/assets/images/solution-electric-function-icon-1.svg'
import functionPic2 from '@/assets/images/solution-electric-function-icon-2.svg'
import functionPic3 from '@/assets/images/solution-electric-function-icon-3.svg'
import functionPic4 from '@/assets/images/solution-electric-function-icon-4.svg'
export default {
  name: "DYNA",
  components: {
    mainNav,
    mainFooter,
    bzInfo
  },
  data(){
    return {
      dynaBannerBg: dynaBannerBg,
      dynaFunctionBg: dynaFunctionBg,
      activeIndex: '3-1',
      chatUrl:"https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
      swiperOption: {
        spaceBetween: 20, // 图片之间的间距
        centeredSlides: false, // 居中还是从图1开始
        slidesPerView: 2, // 一屏幕显示几个? 数字或者默认  auto 自动。
        breakpointsInverse: true,
        breakpoints: {
          //当宽度大于等于1100
          1100: {
            slidesPerView: 4,
            spaceBetween: 10
          }
        },
        notNextTick: true, // true:加载后允许触发事件 false:加载后不可以触发事件
        // loop: true, // 循环吗
        initialSlide: 0, // 从第几个开始
        autoplay: {
          delay: 5000, // 等5秒下一个
          disableOnInteraction: false // 中间滑动一下，取消自动吗？
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }, // 下按钮
        speed: 800, // 滑动时候动画的速度
        paginationClickable: true, // 下面按钮让点吗
        navigation: {
          prevEl: '.swiper-button-prev', // 左侧按钮
          nextEl: '.swiper-button-next' // 右侧按钮
        },
        // effect: 'fade', // 渐入效果
        watchSlidesProgress: true, // 开启这个参数来计算每个slide的progress
        watchSlidesVisibility: true  // 先要开启watchSlidesProgress参数，如果开启watchSlidesVisibility，则会在每个slide增加一个指示该slide的progress值得classname
        // autoHeight: true  // 图片高度自适应
      },
      functionList:[
        {
          icon: functionPic1,
          content: "支持高精度车辆动力学仿真"
        },
        {
          icon: functionPic2,
          content: "具有可独立设定且可复用的多环境因素，支持三种道路模型"
        },
        {
          icon: functionPic3,
          content: "可添加多种交通参与者模型，可触发纵向或横向驾驶任务，支持V2X车辆联网功能的开发"
        },
        {
          icon: functionPic4,
          content: "提供不同级别的虚拟传感器模型"
        },
      ],
    }
  },
  mounted() {
    document.title = "DYNA4 - 产品中心 - 东信创智"
    new this.$wow.WOW().init()
  },
  methods: {
    linkUrl(url){
      window.open(url,'_blank') // 在新窗口打开外链接
      // window.location.href =this.indexro;  //在本页面打开外部链接
    },
  }
}
</script>

<style scoped>

</style>
